<template>
    <page title="Import">
        <div class="row">
            <div class="col col-12 text-end pb-5">
                <b-button-group vertical>
                    <b-btn variant="success" :disabled="isBusy" @click="onClickImportDict">Import słowników<span v-show="isBusy" class="fa fa-spin fa-spinner ms-1"></span></b-btn>
                    <b-btn variant="success" :disabled="isBusy" @click="onClickImportYachts" class="mt-1">Import jachtów<span v-show="isBusy" class="fa fa-spin fa-spinner ms-1"></span></b-btn>
                    <b-btn variant="success" :disabled="isBusy" @click="onClickImportPrices" class="mt-1">Import cen<span v-show="isBusy" class="fa fa-spin fa-spinner ms-1"></span></b-btn>
                    <b-btn variant="success" :disabled="isBusy" @click="onClickImportOccupancy" class="mt-1">Import dostępności<span v-show="isBusy" class="fa fa-spin fa-spinner ms-1"></span></b-btn>
                    <b-btn variant="success" :disabled="isBusy" @click="onClickDownloadPictures" class="mt-1">Pobierz obrazki<span v-show="isBusy" class="fa fa-spin fa-spinner ms-1"></span></b-btn>

                </b-button-group>
            </div>
        </div>
        <div class="row" v-if="log.length > 0">
            <div class="col col-12">
                <div class="max-height m-0 p-0" id="scrollableLog">
                    <table class="table code-like p-2 m-0">
                        <tr v-for="_log in log" :key="_log.id" :class="{'code-like-error': _log.log_type === 'ERROR' || _log.log_type === 'FATAL'}">
                            <td >{{_log.created_at | datetime}}</td>
                            <td class="overflow-hidden"><span v-show="_log.log_short_message.substring(0, 3) === '...'" class="fa fa-spin fa-spinner me-1"></span>{{_log.log_short_message}}</td>
                            <td class="text-end pe-2">{{_log.log_execution_time}} s.</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import page from "@/pages/Common/Page";
import axios from 'axios';
import moment from "moment";
import ImportYachtsOptionsDialog from "@/pages/Import/ImportYachtsOptionsDialog";
import ImportPricesOptionsDialog from "@/pages/Import/ImportPricesOptionsDialog";
import DownloadYachtPicturesOptionsDialog from "@/pages/Import/DownloadYachtPicturesOptionsDialog";

export default {
    name: "MainPage",
    components: {
        page
    },
    data() {
        return {
            isBusy: true,
            log: [],
            refreshTimeout: null,
            lastLog: null,
            lastEndLog: null,
        }
    },
    methods: {

        onClickImport(url, options = {}) {
            if(!this.isBusy) {
                this.lastEndLog = null;
                this.checkIsBusy().then(() => {
                    this.isBusy = true;
                    this.log = [];

                    this.getLastLog().then((log) => {
                        this.lastLog = log;
                        if (this.refreshTimeout) {
                            clearInterval(this.refreshTimeout);
                        }
                        this.refreshTimeout = setInterval(() => this.refreshLog(), 1000);
                        axios.post(url, options)
                            .then(() => {

                            }).finally(() => {
                            this.isBusy = false;
                            if (this.refreshTimeout) {
                                clearInterval(this.refreshTimeout);
                                this.refreshLog();
                            }
                        });
                    }).catch(() => {
                        this.isBusy = false;
                    })
                })
            }
        },
        onClickImportPrices() {
            var that = this;
            this.$modal.show(ImportPricesOptionsDialog, {
                afterOk(options) {
                    that.onClickImport('import/prices', options);
                }
            }, {
                'adaptive': true,
                'height': 'auto',
            })
        },
        onClickImportYachts() {
            var that = this;
            this.$modal.show(ImportYachtsOptionsDialog, {
                afterOk(options) {
                    that.onClickImport('import/yachts', options);
                }
            }, {
                'adaptive': true,
                'height': 'auto',
            })
        },
        onClickImportDict() {
            this.onClickImport('import/dictionaries');
        },
        onClickImportOccupancy() {
            this.onClickImport('import/occupancy');
        },
        onClickDownloadPictures() {
            var that = this;
            this.$modal.show(DownloadYachtPicturesOptionsDialog, {
                afterOk(options) {
                    that.onClickImport('import/pictures', options);
                }
            }, {
                'adaptive': true,
                'height': 'auto',
            })
        },
        getLastEndLog() {
            let filters = JSON.stringify(['log_category|=|API_IMPORT', ['!bool|OR', 'log_type|=|END', 'log_type|=|FATAL']]);
            return new Promise((resolve, reject) => {
                if (this.lastEndLog) {
                    resolve(this.lastEndLog);
                } else {
                    axios.get('puntlogs', {
                        params: {
                            filters: filters
                        }
                    }).then((response) => {
                        if (response.data.data.length > 0) {
                            resolve(response.data.data[0]);
                        } else {
                            resolve(0);
                        }
                    }).catch((error) => {
                        reject(error);
                    });
                }
            });
        },
        getLastLog(onlyImport = false) {
            let filters = JSON.stringify(['log_category|=|API_IMPORT']);


            return new Promise((resolve, reject) => {
                axios.get('puntlogs', {
                    params: {
                        filters: filters
                    }
                }).then((response) => {
                    if (response.data.data.length > 0) {
                        resolve(response.data.data[0]);
                    } else {
                        resolve(0);
                    }
                }).catch((error) => {
                    reject(error);
                });
            })
        },
        refreshLog() {
            return new Promise((resolve, reject) => {
                axios.get('puntlogs_all', {
                    params: {
                        'orderBy': 'id',
                        'orderDir': 'asc',
                        filters: JSON.stringify(['id|>|' +this.lastEndLog.id, 'log_category|=|API_IMPORT']),
                    }
                }).then((response) => {
                    this.log = response.data;
                    this.$nextTick(() => {
                        var elem = document.getElementById('scrollableLog');
                        if (elem) {
                            elem.scrollTop = elem.scrollHeight;
                        }
                    });

                    resolve(response.data);


                }).catch((error) => {
                    reject(error);
                });
            });
        },
        checkIsBusy() {
            return new Promise((resolve, reject) => {
                this.getLastEndLog().then(lastEndLog => {
                    if (!this.lastEndLog) {
                        this.lastEndLog = lastEndLog;
                    }
                    this.refreshLog().then(data => {
                        let _lastLog = null;
                        let _lastLogCreatedAt = null;
                        if (data.length > 0) {
                            _lastLog = data[data.length - 1]
                            _lastLogCreatedAt = moment(_lastLog.updated_at)
                            if (_lastLog.log_type === 'END' || _lastLog.log_type === 'FATAL' || moment().subtract(3, 'minute').isAfter(_lastLogCreatedAt)) {
                                this.isBusy = false;
                                resolve()
                            } else {
                                setTimeout(() => {
                                    this.checkIsBusy().then(() => {
                                        this.isBusy = false;
                                        resolve();
                                    })
                                }, 1000);
                            }
                        } else {
                            this.isBusy = false;
                            resolve();
                        }
                    });
                })
            });
        },
        _checkIsBusy() {
            return new Promise((resolve, reject) => {
                this.getLastLog().then(lastLog => {
                    const lastLogCreatedAt = lastLog ? moment(lastLog.created_at) : null;
                    if (lastLogCreatedAt && lastLog.log_type !== 'END' && lastLogCreatedAt && lastLog.log_type !== 'FATAL' && moment().subtract(3, 'minute').isBefore(lastLogCreatedAt)) {
                        this.lastLog = lastLog;
                        this.isBusy = true;
                        this.refreshLog();
                        this.refreshTimeout = setInterval(() => {
                            this.refreshLog().then((data) => {
                                let _lastLog = null;
                                let _lastLogCreatedAt = null;
                                if (data.length > 0) {
                                    _lastLog = data[data.length - 1]
                                    _lastLogCreatedAt = moment(_lastLog.created_at)
                                } else {
                                    _lastLog = this.lastLog;
                                    _lastLogCreatedAt = moment(this.lastLog.created_at)
                                }
                                console.log('last log created_at', _lastLogCreatedAt.toISOString())
                                if (_lastLog.log_type === 'END' || _lastLog.log_type === 'FATAL' || moment().subtract(3, 'minute').isAfter(_lastLogCreatedAt)) {
                                    clearInterval(this.refreshTimeout);
                                    this.isBusy = false;
                                }

                            });

                        }, 1000);
                        reject();
                    } else {
                        this.isBusy = false;
                        resolve();
                    }
                })
            })
        }
    },
    created() {
        this.checkIsBusy();
    }
}
</script>

<style lang="scss" scoped>
.code-like {
  background-color: black;
  color: greenyellow !important;
  font-size: 13px;
  font-family: monospace;

}
code-like-error {
  color: red !important;
}
.max-height {
  max-height: calc(100vh - 400px);
  //overflow: auto;
  overflow-y: scroll;
}
//tr {
//  height: 25px;
//}

</style>