<template>
<Dialog title="Wybierz opcje" :on-ok="onOk" :on-cancel="onCancel" :on-close="onClose">
    <template v-slot:body>
        <div class="row">
            <div class="col-10">
                Zaczytanie danych z API
            </div>
            <div class="col-2">
                <input-field type="switch" name="import_from_api" v-model="options.import_from_api"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Utworzenie i uzupełnienie tabeli tmp_punt_prices
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yachts_update" v-model="options.create_tmp_table"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Obliczanie cen
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yacht_services_update" v-model="options.calculate_prices"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Obliczanie cen servisów obligatoryjnych (inactive)
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yacht_equipment_update" v-model="options.calculate_obligatory_services"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Utworzenie tabeli punt_prices i skopiowanie danych (inactive)
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yacht_addit_equipment_update" v-model="options.replace_punt_table"></input-field>
            </div>
        </div>
    </template>
</Dialog>
</template>

<script>
import Dialog from "@/pages/Common/Dialog";
import InputField from "@/components/Forms/InputField";
export default {
    name: "TestDialog",
    components: {
        InputField,
        Dialog
    },
    props: {
        afterOk: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            options: {
                import_from_api: true,
                create_tmp_table: true,
                calculate_prices: true,
                calculate_obligatory_services: true,
                replace_punt_table: true,
            }
        }
    },
    methods: {
        onClose() {
            console.log('onClose');
            this.$emit('close');
        },
        onOk() {
            localStorage.setItem('import_prices_options', JSON.stringify(this.options));
            if (this.afterOk) {
                this.afterOk(this.options)
            }
            this.$emit('close');
        },
        onCancel() {
            this.$emit('close');
        }
    },
    created() {
        let jsonOptions = localStorage.getItem('import_prices_options');
        if (jsonOptions) {
            let options = JSON.parse(jsonOptions);
            console.log('options', options);
            if (options) {
                this.options = options;
            }
        }
    }
}
</script>

<style scoped>

</style>