<template>
<Dialog title="Wybierz opcje" :on-ok="onOk" :on-cancel="onCancel" :on-close="onClose">
    <template v-slot:body>
        <div class="row">
            <div class="col-10">
                Zaczytanie danych z API
            </div>
            <div class="col-2">
                <input-field type="switch" name="import_from_api" v-model="options.import_from_api"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Akutalizacja PuntYachts
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yachts_update" v-model="options.punt_yachts_update"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Utworzenie PuntYachtsServices
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yacht_services_update" v-model="options.punt_yacht_services_update"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Utworzenie PuntYachtsEquipments
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yacht_equipment_update" v-model="options.punt_yacht_equipment_update"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Utworzenie PuntYachtsAdditEquipments
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yacht_addit_equipment_update" v-model="options.punt_yacht_addit_equipment_update"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Aktualizacja pól filtrów w PuntYachts
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yachts_filters_update" v-model="options.punt_yachts_filters_update"></input-field>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                Aktualizacja listy zdjęć jachtów
            </div>
            <div class="col-2">
                <input-field type="switch" name="punt_yachts_filters_update" v-model="options.punt_yacht_pictures"></input-field>
            </div>
        </div>
    </template>
</Dialog>
</template>

<script>
import Dialog from "@/pages/Common/Dialog";
import InputField from "@/components/Forms/InputField";
export default {
    name: "TestDialog",
    components: {
        InputField,
        Dialog
    },
    props: {
        afterOk: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            options: {
                import_from_api: true,
                punt_yachts_update: true,
                punt_yacht_services_update: true,
                punt_yacht_equipment_update: true,
                punt_yacht_addit_equipment_update: true,
                punt_yachts_filters_update: true,
                punt_yacht_pictures:true,
            }
        }
    },
    methods: {
        onClose() {
            console.log('onClose');
            this.$emit('close');
        },
        onOk() {
            localStorage.setItem('import_yachts_options', JSON.stringify(this.options));
            if (this.afterOk) {
                this.afterOk(this.options)
            }
            this.$emit('close');
        },
        onCancel() {
            this.$emit('close');
        }
    },
    created() {
        let jsonOptions = localStorage.getItem('import_yachts_options');
        if (jsonOptions) {
            let options = JSON.parse(jsonOptions);
            console.log('options', options);
            if (options) {
                this.options = options;
            }
        }
    }
}
</script>

<style scoped>

</style>